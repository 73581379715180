<template>
    <div>
        <notifications group="foo"/>
        <div class="breadcrumb">
            <h1>Importar Clientes</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="btn th-custom-color col-form-label mr-2">
                                    <input type="file" hidden
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    id="file" ref="file" v-on:change="handleFileUpload()"
                                    >
                                    Seleccionar Archivo
                                </label>
                                <button class="btn th-custom-color mr-2" v-if="file" @click="subir">Subir</button>
                                <router-link to="/cliente">
                                    <button class="btn btn-secondary mr-2">Cancelar</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="btn-group dropright">
                                    <button type="button" class="btn btn-secondary dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                        Descargar plantilla de Clientes
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item"
                                        href="#">Actualizar datos</a>
                                        <span @click="downloadPlantillaNewDatos" style="cursor: pointer"
                                        class="dropdown-item">Nuevos datos</span>
                                        <span style="cursor:pointer" @click="exportData()" class="dropdown-item">Exportar datos</span>
                                    </div>
                                    <button class="btn btn-success ml-3" v-if="clientes.length > 0" @click="store">Guardar</button>
                                </div>
                            </div>
                            <div class="col-sm-6 pt-2">
                                <div v-if="clientes.length > 0">
                                    Total Filas: <span class="font-weight-bold">{{ clientes.length }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="clientes.length == 0 && errors.length == 0">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <br/>
                                    <div class="alert alert-info text-center" role="alert">
                                        <p><strong>¡IMPORTANTE!</strong></p>
                                        Al importar mantener los titulos y las columnas en sus respectivas ubicaciones
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <TheModalPaisReferencia
                                    :paises="paises"
                                />
                            </div>
                            <div class="col-sm-4">
                                <TheModalVendedorReferencia
                                    :vendedores="vendedores"
                                />
                            </div>
                            <div class="col-sm-4">
                                <h6>
                                    <strong>Nota:</strong> <br>
                                    <p>Para Ingresar Emails debe tener este formato <small><strong>correo1@gmail.com, correo2@gmail.com, ...</strong></small>
                                        <br>
                                        Cada Email separado por comas: ","
                                    </p>
                                </h6>
                            </div>
                        </div>
                        <div class="row mt-3"  v-if="errors.length > 0">
                            <div class="col-sm-12 text-center">
                                <h5><strong>¡Errores al subir!</strong></h5>
                            </div>
                            <div v-for="(error, index) in errors" :key="index" class="col-md-3" :class="index %3 ==0? 'offset-2' :''">
                                <template>
                                    <div class="alert alert-warning">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        En la columna {{error.campo}}, fila nro: {{error.fila}} <br>
                                        <p>{{error.error}}</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="row mt-3" v-if="clientes.length > 0">
                            <div class="col-sm-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover w-auto">
                                        <thead class="thead-dark">
                                        <tr>
                                            <th></th>
                                            <th scope="col" style="min-width: 150px;">Nombre Comercial</th>
                                            <th scope="col" style="min-width: 130px;">Razon Social</th>
                                            <th scope="col">CI/NIT</th>
                                            <th scope="col">Codigo Cliente</th>
                                            <th scope="col">Fecha de Trabajo</th>
                                            <th scope="col">Vendedor</th>
                                            <th scope="col">Descripcion</th>
                                            <th scope="col">Telefono</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Direccion</th>
                                            <th scope="col">Pais</th>
                                            <th scope="col" style="min-width: 130px;">Ciudad</th>
                                            <th scope="col">Limite Credito</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(cliente, index) in clientes" :key="index">
                                            <td>
                                                <i class="nav-icon i-Close-Window font-weight-bold text-danger" @click="eliminar(index)" style="cursor: pointer"></i>
                                            </td>
                                            <td>{{ cliente.nombre_comercial }}</td>
                                            <td>{{ cliente.razon_social }}</td>
                                            <td>{{ cliente.cinit }}</td>
                                            <td>{{ cliente.codigo_cliente }}</td>
                                            <td>{{ cliente.fecha_de_trabajo | moment('DD/MM/YYYY')}}</td>
                                            <td>{{ cliente.vendedor }}</td>
                                            <td>{{ cliente.descripcion }}</td>
                                            <td>{{ cliente.telefono }}</td>
                                            <td>{{ cliente.email }}</td>
                                            <td>{{ cliente.direccion }}</td>
                                            <td>{{ cliente.pais }}</td>
                                            <td>{{ cliente.ciudad }}</td>
                                            <td>{{ cliente.limite_credito }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TheModalPaisReferencia from '../components/TheModalPaisReferencia.vue';
import TheModalVendedorReferencia from '../components/TheModalVendedorReferencia.vue';

export default {
    name: 'importar',
    data() {
        return {
            clientes: [],
            file: null,
            errors: [],
            paises: [],
            vendedores: [],
        };
    },
    methods: {
        eliminar(index) {
            this.clientes.splice(index, 1);
        },
        handleFileUpload() {
            this.file = null;
            this.file = this.$refs.file.files[0];
            this.errors = [];
            this.clientes = [];
        },
        subir() {},
        downloadPlantillaNewDatos() {},
        exportData() {},
        store() {},
    },
    computed: {},
    watch: {},
    components: {
        TheModalPaisReferencia,
        TheModalVendedorReferencia,
    },
}
</script>