<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <label class="col-form-label">Referencia ID País:</label>
                <button class="btn th-custom-color" @click="modal.show = true">Ver</button>
            </div>
        </div>
        <b-modal v-model="modal.show" size="md" hide-footer>
            <template slot="modal-title">
                <h5><strong>{{modal.title}}</strong></h5>
            </template>
            <div class="modal-body">

                <div class="row form-group">
                    <div class="col-sm-12">
                        <div style="width: 100%; height: 250px; overflow-y: scroll;">
                            <table class="table table-bordered-condensed table-sm text-center">
                                <thead>
                                <tr class="">
                                    <th width="25%">id</th>
                                    <th width="75%">nombre</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(pais, index) in paises" :key="index">
                                    <td>{{ pais.id }}</td>
                                    <td class="text-left">{{ pais.descripcion }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "ref-pais",
        props: {
            paises: {
                type: Array
            },
        },
        data: () => ({
            modal: {
                title: "Paises",
                show: false,
            },
        }),
        methods: {},
        watch: {},
        computed: {},
        components: {}
    }
</script>

<style scoped>

</style>
